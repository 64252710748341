import React from "react"
import { graphql, PageProps } from "gatsby"
import { usePreviewData } from "../hooks/usePreview"
import { Article } from "../components/article"
import { PrismicArticle } from "../../graphql-types"
import { ArticleContextInterface } from "../context/article"

const ArticleTemplate = ({
  data: staticData,
  pageContext,
}: PageProps<{ prismicArticle: PrismicArticle }, ArticleContextInterface>) => {
  const data = usePreviewData(staticData)
  return (
    <Article prismicArticle={data.prismicArticle} pageContext={pageContext} />
  )
}

export default ArticleTemplate
export { ArticleTemplate }

export const ArticleQuery = graphql`
  query ArticleBySlug($uid: String!, $lang: String!) {
    prismicArticle(uid: { eq: $uid }, lang: { eq: $lang }) {
      ...PrismicArticle
    }
  }
`

export const prismicImageTypeFragment = graphql`
  fragment PrismicImageType on PrismicImageType {
    alt
    copyright
    dimensions {
      height
      width
    }
    fluid(
      srcSetBreakpoints: [200, 400, 600, 800, 1200, 1600, 2000, 2400, 2800]
    ) {
      ...GatsbyPrismicImageFluid
    }
    thumbnails
    url
  }
`

export const prismicArticleBodyVideoFragment = graphql`
  fragment PrismicArticleBodyVideo on PrismicArticleBodyVideo {
    primary {
      closed_captions
      controls
      spacing
      width
      video {
        height
        html
        width
      }

      quiet_loop
      text {
        html
      }
    }
    slice_type
  }
`

export const prismicArticleBodyGalleryFragment = graphql`
  fragment PrismicArticleBodyGallery on PrismicArticleBodyGallery {
    id
    items {
      align
      image {
        ... on PrismicImageType {
          ...PrismicImageType
        }
      }
      justify
      text {
        html
      }
    }
    primary {
      spacing
    }
    slice_type
  }
`

export const prismicArticleBodyColumnFragment = graphql`
  fragment PrismicArticleBodyColumn on PrismicArticleBodyColumn {
    id
    items {
      align
      image {
        ... on PrismicImageType {
          ...PrismicImageType
        }
      }
      justify
      text {
        html
      }
    }
    primary {
      spacing
      width
    }
    slice_type
  }
`

export const prismicArticleBodyCarouselFragment = graphql`
  fragment PrismicArticleBodyCarousel on PrismicArticleBodyCarousel {
    id
    items {
      image {
        ... on PrismicImageType {
          ...PrismicImageType
        }
      }
      text {
        html
        text
      }
    }
    primary {
      align
      spacing
      width
    }
    slice_type
  }
`

export const prismicArticleFragment = graphql`
  fragment PrismicArticle on PrismicArticle {
    alternate_languages {
      lang
      uid
    }
    data {
      body {
        ... on PrismicArticleBodyCarousel {
          ...PrismicArticleBodyCarousel
        }
        ... on PrismicArticleBodyColumn {
          ...PrismicArticleBodyColumn
        }
        ... on PrismicArticleBodyGallery {
          ...PrismicArticleBodyGallery
        }
        ... on PrismicArticleBodyVideo {
          ...PrismicArticleBodyVideo
        }
      }
      excerpt {
        text
      }
      image {
        ... on PrismicImageType {
          ...PrismicImageType
        }
      }
      title
    }
    id
    lang
    prismicId
    type
    uid
  }
`
